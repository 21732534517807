<script>
import { mapState } from "vuex";
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
    },
    es: {
    },
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      rules: null,
    };
  },
  methods: {
    getRules() {
      this.rules = null;

      api.get("station1000/boards/rules").then((response) => {
        if (response.data.status == "success") {
          this.rules = response.data.list;
        }
      });
    },
  },
  mounted() {
    this.getRules();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between align-items-center">
          <h4 class="mb-0 font-size-18">{{ t("Estação 1000") }}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body px-4">
            <div v-for="(list, board) in rules" :key="board">
              <h4 class="mb-4">{{ board }}</h4>
              <ul v-if="list" class="verti-timeline list-unstyled mb-5 ml-3">
                <li class="event-list" v-for="(row, index) in list" :key="index">
                  <div class="event-timeline-dot">
                    <i v-if="row.status == 'blocked'" class="bx bx-block text-danger"></i>
                  </div>
                  <div class="d-flex">
                    <h5>{{ row.name }}</h5>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>